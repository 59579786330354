import { useState } from "react"
export function UI({portalState, setPortalState, setCLickedByHam,clickedbyHam,startExperience, setStartExperience, setHelpState , helpState, contactState,setContactState,contactPage,setContactPage,...props}) {
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  return (
    <>

<div className="fixed top-4 right-4 z-50">
<h1
      className={`xs:text-xs font-extrabold text-white uppercase mb-1 tracking-widest text-right
      ${startExperience === true ? "" : "hidden"}
      `}
    >
      BAPTISTE'S
      <br/>PORFOLIO
    </h1>
</div>




<div className={`fixed top-4 left-4 z-[200] ${startExperience === true ? "" : "hidden"}
      `}>
  <button
    onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}
    className="p-2 rounded-lg text-white font-bold focus:outline-none"
  >
    <div className={`${
      hamburgerMenuOpen ? "space-y-1.5" : "space-y-1"
    } transition-all duration-300 ease-in-out z-101`}>
      <div className="w-8 h-1 bg-white rounded"></div>
      <div className="w-8 h-1 bg-white rounded"></div>
      <div className="w-8 h-1 bg-white rounded"></div>
    </div>
  </button>
  
  <div
    className={`${
      hamburgerMenuOpen ? "translate-y-0 opacity-100" : "-translate-y-10 opacity-0 pointer-events-none"
    } text-white absolute top-12 left-0 p-4 rounded-lg transform transition-all duration-300 ease-in-out`}
  >
    <ul className="space-y-2">
      <li>
        <button onClick={() => {
          setPortalState(0) 
          setHelpState(false)
          setContactState(false)
          setHamburgerMenuOpen(!hamburgerMenuOpen)
          }} className="py-2 px-8 bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100">Home</button>
      </li>
      <li>
        <button onClick={() => {
          setPortalState(0)
          setContactState(true)
          setHelpState(false)
          setCLickedByHam(true)
          setHamburgerMenuOpen(!hamburgerMenuOpen)
        }} className="py-2 px-8 bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100">Contact</button>
      </li>
      <li>
      <button onClick={() => {
          setPortalState(1) 
          setHelpState(false)
          setContactState(false)
          setHamburgerMenuOpen(!hamburgerMenuOpen)
          }} className="py-2 px-8 bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100">Skills</button>
      </li>
      <li>
        <button onClick={() => {
          setPortalState(2) 
          setHelpState(false)
          setContactState(false)
          setHamburgerMenuOpen(!hamburgerMenuOpen)
          
          }} className="py-2 px-8 bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100">Experiences</button>
      </li>
    </ul>
  </div>
</div>












<div className="fixed inset-0 pointer-events-none">
      <section className="flex w-full h-screen flex-col items-center justify-center p-0 duration-500">
        {/* Title */}
        <h1
          className={`text-[min(12vw,2.2rem)] font-bold text-white uppercase mb-1 tracking-widest text-center
          ${startExperience === false ? "" : "hidden"}
          `}
        >
          HEY WELCOME
        </h1>
        <p
          className={`font-bold text-white text-center text-[min(8vw,1.1rem)] max-w-[800px]
          ${startExperience === false ? "" : "hidden"}
          `}
        >
          I'm Baptiste, a System Engineer who lives and work in Madrid.
        </p>
        <p
          className={`font-bold text-white text-center text-[min(8vw,1.1rem)] mb-4 max-w-[800px]
          ${startExperience === false ? "" : "hidden"}
          `}
        >
          Click on start to discover my Portfolio
        </p>
        
        <div className="h-[70%]"></div>

        {/* Button container */}
        <div className="flex space-x-4 sm:space-x-10 justify-center absolute bottom-8 pointer-events-auto">
          {/* NEXT Button */}
          <button
            onClick={() => setPortalState((portalState + 1) % 3)}
            className={`py-[min(12vh,1rem)] px-[min(12vw,2rem)] bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100 text-[min(12vw,1rem)]
              ${helpState === false && startExperience === true && contactState === false ? "" : "hidden"}
            `}
          >
            NEXT
          </button>

          {/* HELP Button */}
          <button
            onClick={() => setHelpState(true)}
            className={`py-[min(12vh,1rem)] px-[min(12vw,2rem)] bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100 text-[min(12vw,1rem)]
              ${helpState === false && startExperience === true && portalState !== 0 ? "" : "hidden"}
            `}
          >
            HELP
          </button>

          {/* CONTACT Button */}
          <button
            onClick={() => setContactState(true)}
            className={`py-[min(12vh,1rem)] px-[min(12vw,2rem)] bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100 text-[min(12vw,1rem)]
              ${startExperience === true && portalState === 0 && contactState === false ? "" : "hidden"}
            `}
          >
            CONTACT
          </button>
        </div>
      </section>
    </div>



<div className="fixed inset-0 pointer-events-none">
      <section
        className={`flex w-full h-full flex-col items-center justify-center duration-500 
        `}
      >
        <div className="h-[80%]"></div>
        <button onClick={() =>
          portalState === 0 ? setContactState(false) : setHelpState(false)
        }
          className={`py-4 px-8 bg-orange-400 text-white font-black rounded-full hover:bg-orange-600 cursor-pointer transition-colors duration-100 pointer-events-auto
          ${helpState === true || contactState === true ? "pointer-events-auto" : "opacity-0 pointer-events-none"}`}
        >
          EXIT
        </button>
      </section>
    </div>
    <div className="fixed inset-0 pointer-events-none">
      <section
        className={`flex w-full h-full flex-col items-center justify-end duration-500 pb-[10vh]
          ${startExperience === false ? "pointer-events-auto" : "opacity-0 pointer-events-none"}`}
      >
        <button
          onClick={() => {
            setStartExperience(true);
            setPortalState(0);
          }}
          className={`py-[min(12vh,0.5rem)] px-[min(12vw,1.5rem)] bg-orange-700 text-white text-[min(12vw,1.5rem)] font-black rounded-full hover:bg-orange-200 hover:text-black cursor-pointer transition-colors duration-500
          ${startExperience === false ? "pointer-events-auto" : "opacity-0 pointer-events-none"}`}
        >
          START
        </button>
      </section>
    </div>



  </>
  );
};