import { Canvas } from "@react-three/fiber";
import { Experience } from "./components/Experience";
import { useState, useEffect } from "react";
import { UI } from "./components/UI";
import { Loader ,useProgress, Html } from "@react-three/drei"
import { UITransition } from "./components/UITransition"

 // Idea for the return. Use the useProgress to check when it is fully loaded. Pass it thought a prop
 // to the UI component. When it is fully loading then make the component visible


function App() {
  const [portalState, setPortalState] = useState(3)
  const [startExperience, setStartExperience] = useState(false)
  const [helpState, setHelpState] = useState(false)
  const [contactState, setContactState] = useState(false)
  const [contactPage, setContactPage] = useState(0)
  const [clickedbyHam, setCLickedByHam] = useState(false)
  const { active, progress, errors, item, loaded, total } = useProgress()

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth < 768)
    checkIsMobile()
    window.addEventListener('resize', checkIsMobile)
    return () => window.removeEventListener('resize', checkIsMobile)
  }, [])

  

  const getLoadingText = () => {
    if (!item) return 'Getting started...';
    
    if (progress < 25) {
      return `Loading`;
    } else if (progress < 50) {
      return `Processing`;
    } else if (progress < 75) {
      return `Almost there with`;
    } else {
      return 'Final touches';
    }
  };

  return (
    <>
   <Loader 
        containerStyles={{ backgroundColor: '#271a12' }} // Customize background
        innerStyles={{ 
          backgroundColor: '#ffefec', 
          width: '150%',   // Increase width
          height: '10px',  // Make the bar thicker
          borderRadius: '10px', // Round the edges
        }}
        barStyles={{ backgroundColor: '#e66b24', borderRadius: '10px', height: '10px', width: '150%'}} // Customize progress bar
        dataStyles={{ color: '#fff', fontSize: '0.8em' }} // Customize text style
        dataInterpolation={(p) => `${getLoadingText()} ... ${progress}%`} // Text
    />
 
    <Canvas camera={{ position: [0, 0, 0], fov:40 }} gl={{ pixelRatio: 2, antialias: true }}> 
    
      <UITransition 
        isMobile = {isMobile}
        setIsMobile = {setIsMobile}
        startExperience={startExperience} 
        setStartExperience={setStartExperience} 
      />
      <Experience 
        isMobile = {isMobile}
        setIsMobile = {setIsMobile}
        portalState={portalState} 
        setPortalState={setPortalState} 
        startExperience={startExperience}
        helpState = {helpState}
        setHelpState = {setHelpState}
        contactState = {contactState}
        setContactState = {setContactState}
        contactPage = {contactPage}
        setContactPage = {setContactPage}
        setCLickedByHam = {setCLickedByHam}
        clickedbyHam = {clickedbyHam}
      />

    </Canvas>
    <UI 
        portalState={portalState} 
        setPortalState={setPortalState} 
        startExperience={startExperience} 
        setStartExperience={setStartExperience} 
        setHelpState = {setHelpState}
        helpState = {helpState}
        contactState = {contactState}
        setContactState = {setContactState}
        contactPage = {contactPage}
        setContactPage = {setContactPage}
        setCLickedByHam = {setCLickedByHam}
        clickedbyHam = {clickedbyHam}
      />
    </>
  );
}
export default App;