/* uniform float uTime;
uniform vec3 u_colorA;
uniform vec3 u_colorB;
uniform float uIntensity;

varying vec2 vUv;

//    Classic Perlin 3D Noise 
//    by Stefan Gustavson
//
vec4 permute(vec4 x){ return mod(((x*34.0)+1.0)*x, 289.0); }
vec4 taylorInvSqrt(vec4 r){ return 1.79284291400159 - 0.85373472095314 * r; }
vec3 fade(vec3 t) { return t*t*t*(t*(t*6.0-15.0)+10.0); }

float cnoise(vec3 P)
{
    vec3 Pi0 = floor(P); // Integer part for indexing
    vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
    Pi0 = mod(Pi0, 289.0);
    Pi1 = mod(Pi1, 289.0);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 / 7.0;
    vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);

    vec4 gx1 = ixy1 / 7.0;
    vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 

    return 2.2 * n_xyz;
}

void main()
{
    // Displace the UV
    vec2 displacedUv = vUv + cnoise(vec3(vUv * 1.0, uTime * 0.1));

    // Perlin noise
    float strength = cnoise(vec3(displacedUv * 5.0, uTime * 0.2));

    // Outer glow
    float outerGlow = distance(vUv, vec2(0.5)) * 30.2 - 1.4;
    strength += outerGlow * 0.0;

    // Apply cool step
    strength += step(- 0.2, strength) * 20.0;

    // Final color
    vec3 color = mix(u_colorB, u_colorA, strength );

    // Set alpha based on strength, with 0.0 for uColorStart (strength = 0) and 1.0 for uColorEnd (strength = 1)
     float alpha = 1.0 * strength * 20.2;

    // Assign the color with alpha
    gl_FragColor = vec4(vec3(1.0), alpha);
} */
uniform float uIntensity;     // Controls the size of the circles
uniform vec2 uResolution;     // Resolution (width and height) of the plane
uniform vec3 u_colorA;        // Color of the first circle
uniform vec3 u_colorB;        // Color of the second circle

varying vec2 vUv;             // UV coordinates of the plane
varying float vZ;

vec3 colorA = vec3(1.000, 0.777, 0.052);  // Color of the first circle (transparent)
vec3 colorB = vec3(0.777, 0.000, 0.000);  // Color of the second circle (solid)

vec3 colorC = vec3(0.912,0.191,0.652);
vec3 colorD = vec3(1.000,0.777,0.052);

void main() {
    // Calculate the aspect ratio to maintain a circular shape
    vec2 aspectRatio = vec2(uResolution.x / uResolution.y, 1.0);
    
    // Adjust the UV coordinates to maintain a circular shape
    vec2 uv = (vUv - 0.5) * aspectRatio + 0.5;

    // Calculate the distance from the center of the plane (0.5, 0.5)
    float distanceFromCenter = distance(uv, vec2(0.5, 0.5));

    // Find the larger dimension (width or height) of the screen
    float maxDimension = max(uResolution.x, uResolution.y);

    // Circle 1 (first circle) radius
    float radius1 = uIntensity * (maxDimension / uResolution.y) * 0.5;

    // Circle 2 (second circle) radius (slightly bigger)
    float radius2 = uIntensity * (maxDimension / uResolution.y) * 0.54;

    // Alpha for the first circle (soft edge, transparent)
    /* float alpha1 = smoothstep(radius1 - 0.01, radius1 + 0.01, distanceFromCenter); */
    float alpha1 = step(radius1, distanceFromCenter);

    // Alpha for the second circle (hard edge, fully solid)
    float alpha2 = step(radius2, distanceFromCenter);

    // If we're within the second (larger) circle, draw it
    if (distanceFromCenter < radius2 && distanceFromCenter >= radius1) {
        gl_FragColor = vec4(colorB, 1.0); // Solid second circle
    } 
    // Otherwise, draw the first circle with transparency
    else if (distanceFromCenter < radius1) {
        gl_FragColor = vec4(u_colorA, 0.0); // Transparent first circle
    } 
    // Outside both circles, set the background color
    else {
        vec3 colorbackground = mix(colorC, colorA, vUv.x * 0.4 + 0.4);

        gl_FragColor = vec4(colorbackground,1.0);
        
    }
}





