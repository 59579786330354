/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef, useEffect, useState} from 'react'
import { useGLTF, Html ,Outlines } from '@react-three/drei'
import * as THREE from "three"
import { useSpring, animated, config} from '@react-spring/three'

export function Mallettes2({animatedPosition3 ,visible,isVisible,textMain ,setIsVisible,HemisphereLightValises, DirectionalLightValises, DirectionalLightPlanet,...props}) {
  const { nodes } = useGLTF('./models/consoletemp.glb')
  const MallettesMaterial = new THREE.MeshStandardMaterial({ color: "#e9994e", metalness:0.2, roughness:3.2 })
  const consolemesh = useRef()
  const [animateStart, setAnimateStart] = useState(false); // State to control animation
  const [animateWorking, setAnimateWorking] = useState(false); // State to control animation
  const [animateUniversity, setAnimateUniversity] = useState(false); // State to control animation
  const [animateEducation, setAnimateEducation] = useState(false); // State to control animation
  const [screenIndex, setScreenIndex] = useState(3)

  const positionHTML=[0.68, 0.8, 0]

  const [springs, api] = useSpring(() => ({
    positionjeu: 0,
    config: config.wobbly,
  }));

  useEffect(() => {
      if (screenIndex == 2) {
        HideTextStart()
        HideTextWorking()
        HideTextUniversity()
      }
      else if (screenIndex == 0) {
        HideTextStart()
        HideTextUniversity()
        HideTextEducation()
      }
      else if (screenIndex == 1) {
        HideTextEducation()
        HideTextStart()
        HideTextWorking()
      }
      else if (screenIndex == 3) {
        HideTextEducation()
        HideTextUniversity()
        HideTextWorking()
      }
      api.start({
        to: async (next) => {
          await next({ positionjeu: -5 });
          await next({ positionjeu: 0 });
        },
        onRest: () => {
          if (screenIndex == 0) {
            animationTextWorking() 
          }
          else if (screenIndex == 1) {
            animationTextUniversity()
          }
          else if (screenIndex == 2) {
            animationTextEducation()
            
          }
          else if (screenIndex == 3) {
            animationTextStart()
            
          }
        },
      });
      
  }, [screenIndex, api]); // Animation will trigger only when `trigger` changes

  // Function to handle click event and trigger animation

  const HideTextStart = () => {
    setAnimateStart(false)
  }

  const HideTextWorking = () => {
    setAnimateWorking(false)
  }

  const HideTextUniversity = () => {
    setAnimateUniversity(false)
  }

  const HideTextEducation = () => {
    setAnimateEducation(false)
  }
  const animationTextStart = () => {
    if (screenIndex == 3) {
      setAnimateStart(true)
  }
  }

  const animationTextWorking = () => {
    if (screenIndex == 0) {
      setAnimateWorking(true)
  }
  }
  const animationTextUniversity = () => {
    if (screenIndex == 1) {
      setAnimateUniversity(true)
  }
  }
  const animationTextEducation = () => {
    if (screenIndex == 2) {
      setAnimateEducation(true)
  }
  }
  const degToRad = (degrees) => degrees * (Math.PI / 180)
  
  const ServerMaterialBlack = new THREE.MeshStandardMaterial({ color: "#251612", metalness:0.2, roughness:3.2 })
  return (
    <>
    <animated.group {...props} rotation={[0, -Math.PI /2, 0]} position-y={animatedPosition3} visible={visible} >
      <mesh
        onClick={() => {
          setScreenIndex((screenIndex +1) % 3)
        }}
        geometry={nodes.console.geometry} 
        material={MallettesMaterial} 
        ref={consolemesh} >
      <Outlines thickness={2} color="black"  />
      </mesh>
      <mesh 
        geometry={nodes.screen.geometry}
      >
        <meshBasicMaterial color={"#351f1a"} />
      </mesh>
      <animated.group position-y={springs.positionjeu}>
      <mesh
      onClick={() => {
        setScreenIndex((screenIndex +1) % 3)
      }}
        geometry={nodes.jeu.geometry}
        material={MallettesMaterial}
      >
        <Outlines thickness={2} color="black"  />
      </mesh>
      <mesh
        geometry={nodes.decojeu.geometry}
        material={nodes.decojeu.material}
      >
         <meshBasicMaterial color={"black"} />
         <Outlines thickness={2} color="black"  />
      </mesh>
      <mesh
        geometry={nodes.visjeu.geometry}
        material={nodes.vis.material}
        >
        <meshBasicMaterial color={"black"} />
      </mesh>
      </animated.group>
      
      <mesh
        geometry={nodes.vis.geometry}
        material={nodes.vis.material}
      >
        <meshBasicMaterial color={"black"} />
      </mesh>
      
      <group >
      <Html position={positionHTML} scale={0.17} transform rotation={[0, Math.PI / 2, 0]} occlude={[consolemesh]} zIndexRange={[0, -1]} 
      distanceFactor={10}
      style={{
        transition: 'opacity 0.5s',
        opacity: 0.7,
        backgroundColor: 'transparent',
        fontFamily: 'Bungee, sans-serif',
        letterSpacing: '0.9em', // Adjust letter spacing here
      }}
      >
        <div className={screenIndex === 0 && visible === true ? "" : "hidden"}>
          <div className={animateWorking ? "typewriter bg-white p-5 rounded" : "opacity-0"}>
            {/* Tailwind and custom blink animation */}
            <p className="text-4xl font-bold text-black font-bungee typewriterwithout ">
              &gt;&gt;WORK EXPERIENCES ----
            </p>
            
         </div>
         <br/>
         <div className={animateWorking ? "typewriterwithout text-3xl" : "opacity-0"} >
   
            <h1 className="font-bold" >&gt;_SYSTEM ENGINEER EUC</h1>
          </div>
          <div className={animateWorking ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE :  2024 - NOW<br/>COMPANY : Axpo<br/>LOCATION : Madrid, SPAIN</h1><h1>_____________________________________</h1>
          </div>
          <br/>
          <div className={animateWorking ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold" >&gt;_SYSTEM ENGINEER</h1>
          </div>
          <div className={animateWorking ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE : 2021 - 2024<br/>COMPANY : Capgemini<br/>LOCATION : Chambery, FRANCE</h1><h1>_____________________________________</h1>
          </div>
          <br/>
          <div className={animateWorking ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold" >&gt;_CIVIC SERVICE</h1>
          </div>
          <div className={animateWorking ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE : 2017 - 2018<br/>COMPANY: SECONDARY SCHOOL VENTADOUR<br/>LOCATION : Privas, FRANCE</h1><h1>_____________________________________</h1>
          </div>
          <br/>
          <div
          onClick={() => {
            setScreenIndex((screenIndex +1) % 3)
          }} 
          className={animateWorking ? "typewriter bg-white p-2 rounded flex justify-center items-center mt-12" : "opacity-0"}>
            {/* Tailwind and custom blink animation */}
            <p className="text-2xl font-bold text-black font-bungee typewriterwithout text-center">
                       &gt;&gt;CONTINUE&lt;&lt;
            </p>
         </div>
          
          </div>
          



          
        </Html>

        <Html position={positionHTML} scale={0.17} transform rotation={[0, Math.PI / 2, 0]} occlude={[consolemesh]} zIndexRange={[0, -1]} 
      distanceFactor={10}
      style={{
        transition: 'opacity 0.5s',
        opacity: 0.7,
        backgroundColor: 'transparent',
        fontFamily: 'Bungee, sans-serif',
        letterSpacing: '0.9em', // Adjust letter spacing here
      }}
      >
        <div className={screenIndex === 3 && visible !== false ? "" : "hidden"}>
          <div
          onClick={() => {
            setScreenIndex((screenIndex +1) % 3)
          }}  
          className={animateStart ? "typewriter bg-white p-8 rounded" : "opacity-0"}>
            {/* Tailwind and custom blink animation */}
            <p className="text-4xl font-bold text-black font-bungee typewriterwithout ">
              &gt;&gt;CLICK TO START
            </p>
         </div>
         </div>



          
        </Html>






        <Html position={positionHTML} scale={0.17} transform rotation={[0, Math.PI / 2, 0]} occlude={[consolemesh]} zIndexRange={[0, -1]} 
      distanceFactor={10}
      style={{
        transition: 'opacity 0.5s',
        opacity: 0.7,
        backgroundColor: 'transparent',
        fontFamily: 'Bungee, sans-serif',
        letterSpacing: '0.9em', // Adjust letter spacing here
      }}
      >
        <div className={screenIndex === 1 && visible === true ? "" : "hidden"}>
        <div className={animateUniversity ? "typewriter bg-white p-5 rounded" : "opacity-0"}>
            {/* Tailwind and custom blink animation */}
            <p className="text-4xl font-bold text-black font-bungee typewriterwithout ">
              &gt;&gt;EDUCATION ----------
            </p>
         </div>
         <br/>
          <div className={animateUniversity ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold" >&gt;_MASTER 2 NETWORK & TELECOM</h1>
          </div>
          <div className={animateUniversity ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE : 2020 - 2021<br/>UIVERSITY : Savoie Mont Blanc<br/>PLACE : Chambery, France</h1><h1>_____________________________________</h1>
          </div>
          <br/>
          <div className={animateUniversity ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold">&gt;_MASTER 1 NETWORKS & TELECOM</h1>
          </div>
          <div className={animateUniversity ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE : 2019 - 2020<br/> UIVERSITY : METROPOLIA<br/>PLACE : Helsinki, Finland<br/>_____________________________________</h1>
          </div>
          <br/>
          <div className={animateUniversity ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold">&gt;_DEGREE NETWORK & TELECOMS</h1>
          </div>
          <div className={animateUniversity ? "typewriterwithout text-2xl" : "opacity-0"} >
          <h1>DATE : 2015 - 2018<br/> UIVERSITY : Savoie Mont Blanc<br/>PLACE : Chambery, France<br/>_____________________________________</h1>
          </div>
          <br/>
          <div
          onClick={() => {
            setScreenIndex((screenIndex +1) % 3)
          }} 
          className={animateUniversity ? "typewriter bg-white p-2 rounded flex justify-center items-center mt-16" : "opacity-0"}>
            {/* Tailwind and custom blink animation */}
            <p className="text-2xl font-bold text-black font-bungee typewriterwithout text-center">
                       &gt;&gt;CONTINUE&lt;&lt;
            </p>
         </div>
          </div>
          </Html>



          <Html position={positionHTML} scale={0.17} transform rotation={[0, Math.PI / 2, 0]} occlude={[consolemesh]} zIndexRange={[0, -1]} 
      distanceFactor={10}
      style={{
        transition: 'opacity 0.5s',
        opacity: 0.7,
        backgroundColor: 'transparent',
        fontFamily: 'Bungee, sans-serif',
        letterSpacing: '0.9em', // Adjust letter spacing here
      }}
      >
        <div className={screenIndex === 2 && visible === true ? "" : "hidden"}>
        <div className={animateEducation ? "typewriter bg-white p-5 rounded" : "opacity-0"}>
            {/* Tailwind and custom blink animation */}
            <p className="text-4xl font-bold text-black font-bungee typewriterwithout ">
              &gt;&gt;CERTIFICATIONS ------
            </p>
         </div>
         <br/>
          <div className={animateEducation ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold" >&gt;_KUBERNETES CKA</h1>
          </div>
          <div className={animateEducation ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE :  04/2024<br/>COMPANY : The Linux Foundation</h1><h1>_____________________________________</h1>
          </div>
          <br/>
          <div className={animateEducation ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold">&gt;_VMWARE HORIZON CERTIFIED</h1>
          </div>
          <div className={animateEducation ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE : 02/2024<br/> COMPANY : VMware, BROADCOM<br/>SPECIALITY : Desktop Management</h1><h1>_____________________________________</h1>
          </div>
          <br/>
          <div className={animateEducation ? "typewriterwithout text-3xl" : "opacity-0"} >
            <h1 className="font-bold">&gt;_NUTANIX NCP EUC 6</h1>
          </div>
          <div className={animateEducation ? "typewriterwithout text-2xl" : "opacity-0"} >
            <h1>DATE : 2023<br/>COMPANY : Nutanix <br/>SPECIALITY : End User Computing</h1><h1>_____________________________________</h1>
          </div>
          <br/>
          <br/>
          <div
          onClick={() => {
            setScreenIndex((screenIndex +1) % 3)
          }} 
          className={animateEducation ? "typewriter bg-white p-2 rounded flex justify-center items-center mt-16" : "opacity-0"}>
            {/* Tailwind and custom blink animation */}
            <p className="text-2xl font-bold text-black font-bungee typewriterwithout text-center">
                       &gt;&gt;CONTINUE&lt;&lt;
            </p>
         </div>
          </div>
          </Html>
        </group>
         
      
    </animated.group>
    
    </>
  )
}

useGLTF.preload('./models/consoletemp.glb')